import { FC } from 'react';
import { useDispatch } from 'react-redux';

import {
  fetchAchievements,
  fetchUserAchievements,
} from '../../core/achievements/achievements.actions';
import { notifyAchievement } from '../../core/achievements/achievements.utils';
import useEvent from '../../core/hooks/useEvent.hook';
import { useGamification } from '../../gamification/hooks/gamification.hook';
import { useMe } from '../../profile/hooks';
import { usePushService } from '../../pubsub/hooks/pubsub.hook';
import store from '../../shared/Store';
import './GamificationProvider.scss';

type GamificationProviderProps = Record<string, undefined>;

const { gamification = {} } = window.__DATA__;
const GamificationProvider: FC<GamificationProviderProps> = () => {
  const dispatch = useDispatch();
  const me = useMe();

  usePushService(gamification.enabled ? store.userId : null);

  const onGamificationMessage = useEvent((type: string, message: any) => {
    // TODO: Better idea than refresh everything ?
    if (gamification.enabled) {
      notifyAchievement(message?.payload?.achievement);
      dispatch(fetchAchievements());
      dispatch(fetchUserAchievements(me._id));
    }
  });

  useGamification(onGamificationMessage);
  return null;
};

export { GamificationProvider };
