/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { useFreshAgendaWorkshops } from '../../agenda/store/agenda.hooks';
import { useConfig } from '../../config/config.context';
import feedbackService from '../../core/services/feedback.service';
import { useMe } from '../../profile/hooks';
import FeedbackModal from '../cms/WorkshopFeedbackPopupBlock/FeedbackModal';

type FeedbackState = {
  uri?: string;
  feedbackId?: string;
  session?: any;
};

const FeedbackModalChecker = (): JSX.Element | null => {
  // Check if we have sessions with feedback
  const me = useMe();
  const [feedbackState, setFeedbackState] = useState<FeedbackState>({});
  //   const [feedbackUrl, setFeedbackUrl] = useState(null);
  const agendaSessions = useFreshAgendaWorkshops();
  const sessionsWithFeedback = agendaSessions.filter((session: any) =>
    session?.feedback?.find((f: any) => f.visible && f.uri),
  );
  const hasFeedbacks = sessionsWithFeedback.length > 0;

  // Check for feedback every 5 minutes
  const { data: feedbackData } = useQuery(
    ['feedback', 'live'],
    () => feedbackService.getMissingFeedbacks(me._id),
    {
      enabled: hasFeedbacks && !!me._id,
      refetchOnReconnect: true,
      refetchOnWindowFocus: true,
      refetchInterval: 5 * 60 * 1000,
    },
  );
  //   console.log('sessionsWithFeedback', sessionsWithFeedback);
  useEffect(() => {
    if (feedbackData?.feedbackToDisplay) {
      const { feedbackId, session, feedbackUrl } = feedbackData?.feedbackToDisplay;
      if (feedbackState.uri !== feedbackUrl) {
        setFeedbackState({
          uri: feedbackUrl,
          feedbackId,
          session,
        });
      }
    }
  }, [feedbackData]);

  if (!feedbackState.uri) {
    return null;
  }

  return (
    <FeedbackModal
      item={feedbackState.session}
      onClose={() => setFeedbackState({})}
      feedbackUrl={feedbackState.uri}
      autoClose
    />
  );
};

/**
 * Auto-disabled if doesn't have anything useful
 * @returns
 */
const FeedbackChecker = React.memo((): JSX.Element | null => {
  const config = useConfig();
  if (!config?.liveFeedback?.enabled) {
    return null;
  }

  return <FeedbackModalChecker />;
});

export default FeedbackChecker;
