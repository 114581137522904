import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Container } from 'semantic-ui-react';

import UserColoredCalendarBlock from '../agenda/blocks/UserColoredCalendarBlock';
import { CmsPageLayout } from '../cms/CmsPage';
import { CmsBlocks } from '../cms/CmsScreen';
import GridContainer from '../components/GridContainer';
import { useScreenConfig } from '../config/screens.context';
import { withScreenClosed } from '../core/screens/screens.hoc';
import './Appointments.scss';
import AppointmentsBlock from './AppointmentsBlock';

const Main = (props) => {
  const { pageId } = props;
  const screen = useScreenConfig(pageId);
  const { main = {}, header, footer, days = [], appointmentUserRef = {} } = screen;
  const { collection, groupId } = appointmentUserRef;

  return (
    <AppointmentsBlock
      main={main}
      header={header}
      footer={footer}
      days={days}
      appointmentUserRef={{ _id: groupId, collection }}
    />
  );
};

Main.defaultProps = {
  pageId: 'appointments',
};

Main.propTypes = {
  pageId: PropTypes.string,
};

const sidebarComponents = {
  agenda: UserColoredCalendarBlock,
};

const Appointments = (props) => {
  const { pageId, metadata, design, pageHeader, pageFooter } = props;
  const { header, footer, sidebar } = useScreenConfig(pageId);
  return (
    <CmsPageLayout
      className={cx('page', 'page--appointments', pageId)}
      header={header}
      metadata={metadata}
      designOverride={design}
      pageHeader={pageHeader}
      pageFooter={pageFooter}
    >
      <Container>
        <div className="appointments--screen" style={{ marginTop: 14 }}>
          <GridContainer sidebar={sidebar} sidebarComponents={sidebarComponents}>
            {header && <CmsBlocks blocks={header.blocks} />}
            <Main pageId={pageId} />
            {footer && <CmsBlocks blocks={footer.blocks} />}
          </GridContainer>
        </div>
      </Container>
    </CmsPageLayout>
  );
};

Appointments.defaultProps = {
  design: {},
  metadata: undefined,
  pageFooter: undefined,
  pageId: 'appointments',
  pageHeader: undefined,
};

Appointments.propTypes = {
  design: PropTypes.object,
  metadata: PropTypes.object,
  pageFooter: PropTypes.object,
  pageId: PropTypes.string,
  pageHeader: PropTypes.object,
};

export default withScreenClosed(Appointments, { className: 'page--appointments' });
